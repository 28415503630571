<template>
  <div class="content__body">
    <form class="form" @submit.prevent="onCheckForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="name">Название<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
              <input
                id="name"
                type="text"
                class="form-control__input"
                name="name"
                placeholder="Введите название"
                v-model.trim="form.name"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label>Основной контрагент 1С<sup>*</sup></label>
            <v-select
              label="name"
              v-model="form.main_counterparty"
              :filterable="false"
              :clearable="false"
              :options="counterparty_codes_options"
              @search="onCodesSearch"
              placeholder="Выберите основного контрагента 1C"
              class="select"
              :class="{ 'select--error': $v.form.main_counterparty.$error }"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите свой запрос для поиска</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }} (Code: {{ option.code_1c }})
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }} (Code: {{ option.code_1c }})
                </div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.main_counterparty.$dirty && !$v.form.main_counterparty.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label>Дополнительные контрагенты 1С</label>
            <v-select
              label="name"
              v-model="form.counterparties"
              :filterable="false"
              :clearable="false"
              :options="counterparty_codes_options"
              @search="onCodesSearch"
              placeholder="Выберите дополнительных контрагентов 1C"
              class="select select--multiple"
              multiple
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите свой запрос для поиска</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }} (Code: {{ option.code_1c }})
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label for="instagram_url">Instagram</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.instagram_url.$error }">
              <input
                id="instagram_url"
                type="url"
                class="form-control__input"
                name="instagram_url"
                placeholder="Введите instagram"
                v-model.trim="form.instagram_url"
                @change="$v.form.instagram_url.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.instagram_url.$dirty && !$v.form.instagram_url.url">Поле должно быть ссылкой</div>
          </div>
          <div class="form-group">
            <label for="instagram_url">Комментарий</label>
            <div class="form-control">
              <textarea
                id="comment"
                class="form-control__textarea"
                name="comment"
                placeholder="Добавьте комментарий"
                v-model.trim="form.comment"
              />
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="address">Адрес</label>
            <div class="form-control">
              <input
                id="address"
                type="text"
                name="address"
                class="form-control__input"
                placeholder="Не заполнено в 1с"
                v-model.trim="form.address"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="email">Электронная почта</label>
            <div class="form-control">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Не заполнено в 1с"
                v-model.trim="form.email"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="phone_number">Телефон</label>
            <div class="form-control">
              <input
                v-model="form.phone_number"
                name="phone_number"
                placeholder="Не заполнено в 1с"
                class="form-control__input"
                id="phone_number"
                autocomplete="off"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="filial">Филиал</label>
            <div class="form-control">
              <input
                id="filial"
                type="text"
                name="filial"
                class="form-control__input"
                placeholder="Не заполнено в 1с"
                :value="getCurrentBranch"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }" :disabled="$v.form.$error">
          <span>Сохранить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
import { debounce } from 'lodash'

export default {
  name: 'ClientDetail',
  mixins: [ validationMixin ],
  validations: {
    form: {
      name: { required },
      instagram_url: { url },
      main_counterparty: { required }
    },
  },
  data() {
    return {
      form: {
        branch: ''
      },
      counterparty_codes_options: [],
      loading: false,
    }
  },
  mounted() {
    this.$store.dispatch('client/GET_DATA', { id: this.$route.params.id })
      .then(response => {
        this.form = response.data
      })
  },
  computed: {
    getCurrentBranch() {
      return this.form.branch === 'S' ? 'Ставрополь' : this.form.branch === 'R' ? 'Ростов' : 'Общие'
    },
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      const formData = {
        main_counterparty: this.form.main_counterparty.code_1c,
        counterparties: this.form.counterparties.map(item => item.code_1c).filter(item => item !== this.form.main_counterparty.code_1c),
        name: this.form.name,
      }
      if (this.form.instagram_url || this.form.instagram_url === '') formData.instagram_url = this.form.instagram_url
      if (this.form.comment || this.form.comment === '') formData.comment = this.form.comment
      console.log(formData)
      this.$store.dispatch('clients/PUT_DATA', { data: formData, id: this.$route.params.id })
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'clients-list' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Клиент успешно отредактирован.'
          })
        })
        .catch(error => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: error.response.data.error
          })
        })
    },
    onCodesSearch (search, loading) {
      loading(true)
      this.codesOrg(loading, search, this)
    },
    codesOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('dict/GET_COUNTERPARTIES', search).then(response => {
        vm.counterparty_codes_options = response.data.results
        loading(false)
      })
    }, 350),
  }
}
</script>
